import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";
import { ClientForm } from "../../components/form/clientForm";
import { useGlobalContext } from "../../hooks/globalContext";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../migration/shared/ui/Button";
import { InputDate } from "../../components/input/inputDate";

export function History() {
    const { api, i18n } = useGlobalContext();

    useEffect(() => {
        document.title = i18n.download_history();
    }, [i18n]);

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
    };

    const [loading, setLoading] = useState(false);

    function getHistory() {
        if (!selectedDate) return;

        const formattedDate = selectedDate.toISOString().split('T')[0];
        api.getHistory({ setLoading: setLoading }, formattedDate)
            .then(blob => {
                const url = URL.createObjectURL(blob);
                console.log(url);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'user-history.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error('Ошибка при получении истории:', err);
            });
    }

    return (
        <section className="app__main">
            <main role="main" className="app__content">
                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.download_history()}</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">
                            {/* Используем компонент InputDate, передавая текущую дату и обработчик */}
                            <InputDate
                                label="Выберите дату"
                                value={selectedDate ?? new Date()} // Если дата не выбрана, передаем текущую
                                onChange={handleDateChange}
                                isValidate={false}
                            />

                            <Button text={`Скачать историю: ${selectedDate ? selectedDate.toISOString().split('T')[0] : ''}`} onClick={() => getHistory()} />
                        </div>
                    </div>
                </div>
            </main>
        </section>
    );
}
